/* eslint-disable */
// @ts-ignore

import {
  defineComponent,
  h,
  compile
} from 'vue';

import {
  LitLink,
  LitAffix,
  LitTip,
  LitAlert,
  BurTabs,
  LitIconSvg,
  Attachment,
  BurScrollSpy,
  BurAccordion,
  BurAccordions,
  BurScoreboard,
  BurBreadcrumbs,
  BurModalModules,
  BurCardCarousel,
  BurGeorgeBanner,
  BurProductGuide,
  BurWhatCanBeFund,
  BurSideMenuForFuture,
  BurWhatCanBeFundHouse,
  BurHeroBannerCarousel,
  BurAccordionForFuture,
  BurAccordionsForFuture,
  BurGoogleAnalyticsButton,
  BurCallbackForm,
  BurCallbackFormModal,
  BurEmailCallbackForm,
  BurPhoneCallbackForm,
  CalculatorLoan,
  CalculatorLoanCofinance,
  CalculatorMortgage,
  CalculatorLoanForFuture,
  CalculatorLoanForFutureWithSubsidy,
  CalculatorLoanFromBurinka,
  CalculatorBuildingSavings,
  CalculatorCooperativeHousing,
  CalculatorMortgageRefinancing,
  CalculatorBuildingSavingsAction,
  CalculatorLoanCompanyContactForm,
  CalculatorRefinancingAndConsolidationLoan,
  CalculatorLoanRefinancingWithoutCollateral,
  LoanEarlyRepaymentCalculator,
} from '@burinka/visual-library/dist/components';

import {
  modalHandler
} from '@burinka/visual-library/dist/directives';

export default defineComponent({
  name: 'RenderContent',

  props: {
    html: {
      type: String,
      default: ''
    },
  },

  components: {
    LitLink,
    LitAffix,
    LitTip,
    LitAlert,
    BurTabs,
    LitIconSvg,
    Attachment,
    BurScrollSpy,
    BurAccordion,
    BurAccordions,
    BurScoreboard,
    BurBreadcrumbs,
    BurModalModules,
    BurCardCarousel,
    BurGeorgeBanner,
    BurProductGuide,
    BurWhatCanBeFund,
    BurSideMenuForFuture,
    BurWhatCanBeFundHouse,
    BurHeroBannerCarousel,
    BurAccordionForFuture,
    BurAccordionsForFuture,
    BurGoogleAnalyticsButton,
    BurCallbackForm,
    BurCallbackFormModal,
    BurEmailCallbackForm,
    BurPhoneCallbackForm,
    CalculatorLoan,
    CalculatorLoanCofinance,
    CalculatorMortgage,
    CalculatorLoanForFuture,
    CalculatorLoanForFutureWithSubsidy,
    CalculatorLoanFromBurinka,
    CalculatorBuildingSavings,
    CalculatorCooperativeHousing,
    CalculatorMortgageRefinancing,
    CalculatorBuildingSavingsAction,
    CalculatorLoanCompanyContactForm,
    CalculatorRefinancingAndConsolidationLoan,
    CalculatorLoanRefinancingWithoutCollateral,
    LoanEarlyRepaymentCalculator
  },

  directives: {
    // @ts-ignore
    modalHandler
  },


  setup (props) {
    return compile(props.html);
  }
});
